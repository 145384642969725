<template lang="pug">
include ../../../configs/template
div.row
  div.col-sm-12.col-md-4.text-left
    +data-info('number', 'sailorDocument.number')
  div.col-sm-12.col-md-4.text-left
    +data-info('serial', 'sailorDocument.serial')
  div.col-sm-12.col-md-4.text-left
    +data-info('group', 'sailorDocument.group')
  div.col-sm-12.text-left
    +data-info('nameInstitution', 'sailorDocument.name_nz[labelName]')
  div.col-sm-12.text-left
    +data-info('way', 'sailorDocument.faculty[labelName]')
  div.col-sm-12.text-left
    +data-info('educationForm', 'sailorDocument.education_form[labelName]')
  div.col-sm-12.col-md-6.text-left
    +data-info('dataEnrollment', ' getDateFormat(sailorDocument.date_start)')
  div.col-sm-12.col-md-6.text-left
    +data-info('dateEnd', ' getDateFormat(sailorDocument.date_end)')
  div.col-sm-12.text-left
    +data-info('educationWithSQC', 'educationWithSQC')
  div.col-sm-12.text-left
    +data-info('passedEducationExam', 'passedEducationExam')
  div.col-sm-12.text-left
    +data-info-status('status', 'sailorDocument.status_document[labelName]', 'getStatus(sailorDocument.status_document.id)')

</template>

<script>
import { mapState } from 'vuex'
import { getDateFormat, getStatus } from '@/mixins/main'

export default {
  name: 'SailorStudentInfo',
  props: {
    sailorDocument: { type: Object, default: () => {} }
  },
  data () {
    return {
      getDateFormat,
      getStatus
    }
  },
  computed: {
    ...mapState({
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    educationWithSQC () {
      return this.sailorDocument.educ_with_dkk ? this.$t('yes') : this.$t('no')
    },
    passedEducationExam () {
      return this.sailorDocument.passed_educ_exam ? this.$t('yes') : this.$t('no')
    }
  }
}
</script>
